const Homepage = () => {
  return (
    <div className="flex items-center justify-center h-[100vh] w-full">
      <div className="p-10 bg-[yellow] border-[6px] border-[#fff] rounded-lg">
        <h1 className="text-center lg:text-[40px] sm:text-[30px] text-[20px] font-bold italic bg-[yellow]">
          Hello CloudFlare
        </h1>
        <p className="text-center font-medium lg:text-[20px] sm:text-[18px] text-[16px] sm:py-4 py-2 bg-[yellow]">
          This is a test for a React application in Cloudflare
        </p>
      </div>
    </div>
  );
};
export default Homepage;
