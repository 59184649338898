import { Routes, Route } from "react-router-dom";
import Home from "../container/home";
import Layout from "../shared/index";

const AllPagesRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </Layout>
  );
};
export default AllPagesRoutes;
