import AllPagesRoutes from "./routes";
import "./style/index.css";
function App() {
  return (
    <>
      <AllPagesRoutes />
    </>
  );
}

export default App;
